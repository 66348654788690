<template>
    <div class="preloader"></div>
</template>

<script>
export default {
    name: 'PreloaderComponent',
}
</script>

<style scoped>
</style>
