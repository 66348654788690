<template>
   <div class="backtotop">
      <a href="#" class="scroll">
        <i class="far fa-arrow-up"></i>
      </a>
    </div>
</template>
  
<script>
export default {
  name: 'BackToTop',
}
</script>

<style scoped>
</style>
  