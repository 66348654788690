<template>
    <div class="header_top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col col-md-12">
                    <p class="header_hotline">Тел: <strong>8 (800) 505-65-21</strong></p>
                </div>
            </div>
        </div>
    </div>
 </template>
   
 <script>
 export default {
   name: 'HeaderTop',
 }
 </script>
 
 <style scoped>
 </style>
   